import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import GET_CUSTOM_PAGES from "./gql";

import "./index.scss";

import FacebookIcon from "./assets/facebook-icon.png";
import EmailIcon from "./assets/email-icon.png";

let FOOTER_LINKS = [
  {
    name: "Our Story",
    href: "/our-story",
  },
  {
    name: "Line Up",
    href: "/line-up",
  },
  {
    name: "Programme",
    href: "/programme",
  },
  {
    name: "Tickets",
    href: "/tickets",
  },
  {
    name: "Downloadable Programme",
    href: "https://raundsfestival.com/Prog2024.pdf"
  },
  {
    name: "Venues",
    href: "/venues",
  },
  {
    name: "Accommodation",
    href: "/accommodation",
  },
  {
    name: "Gallery",
    href: "/gallery",
  },
  {
    name: "Supporters",
    href: "/supporters",
  },
  {
    name: "Donate",
    href: "https://www.buymeacoffee.com/RaundsFestival",
  },
];

const Footer = () => {
  const { data, error, loading } = useQuery(GET_CUSTOM_PAGES);
  console.log(data);

  return (
    <footer>
      <section>
        <h2>Festival Main Stage</h2>
        <p>The Saxon Hall,</p>
        <p>Thorpe Street,</p>
        <p>Raunds,</p>
        <p>Wellingborough,</p>
        <p>Northants,</p>
        <p>NN9 6LT</p>
        <br />
        <a href="/contact-us">CONTACT US</a>
      </section>
      <section>
        <section className="page-links">
          {FOOTER_LINKS.map(({ name, href }, index) => (
            <div key={index}>
              <a href={href}>{name}</a>
            </div>
          ))}
          {!loading &&
            !error &&
            data?.pages?.map(({ id, tinyTitle }) => (
              <div key={id}>
                <a href={`/page/${id}`}>{tinyTitle}</a>
              </div>
            ))}
          <div>
            <a href="/contact-us">
              <img src={EmailIcon} alt="email icon" />
            </a>
          </div>
          <div>
            <a href="https://www.facebook.com/raundsfestival">
              <img src={FacebookIcon} alt="facebook icon" />
            </a>
          </div>
        </section>
      </section>
    </footer>
  );
};

export default Footer;
