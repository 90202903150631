import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Cookies from "js-cookie";
import { CookieBanner } from "@palmabit/react-cookie-law";
import ReactGA from "react-ga";
import Nav from "./common/components/nav";
import Footer from "./common/components/footer";
import BootPrints from "./common/components/boot-prints";

import "./styles/general.scss";

const Homepage = lazy(() => import("./pages/home"));
const OurStoryPage = lazy(() => import("./pages/our-story"));
const LineUpPage = lazy(() => import("./pages/line-up"));
const ArtistPage = lazy(() => import("./pages/artist"));
const TicketsPage = lazy(() => import("./pages/tickets"));
const ProgrammePage = lazy(() => import("./pages/programme"));
const SupportersPage = lazy(() => import("./pages/supporters"));
const ContactUsPage = lazy(() => import("./pages/contact-us"));
const VenuesPage = lazy(() => import("./pages/venues"));
const AccommodationPage = lazy(() => import("./pages/accommodation"));
const GalleryPage = lazy(() => import("./pages/gallery"));
const NewsPage = lazy(() => import("./pages/news"));
const NotFoundPage = lazy(() => import("./pages/not-found"));
const Page = lazy(() => import("./pages/page"));

const NO_BOOT_PATHS = ["/our-story", "/page/"];

const App = () => {
  const displayBoots = !NO_BOOT_PATHS.find((path) =>
    window.location.pathname.includes(path)
  );
  const [canTrackUser, setCanTrackUser] = useState(false);
  const [isGaInitialised, setIsGaInitialised] = useState(false);

  useEffect(() => {
    // Are we allowed to track the user?
    if (
      canTrackUser ||
      (Cookies.get("rcl_consent_given") &&
        Cookies.get("rcl_preferences_consent") &&
        Cookies.get("rcl_statistics_consent"))
    ) {
      if (!canTrackUser) setCanTrackUser(true); // avoids needing to read cookies again

      // Only init GA once
      if (!isGaInitialised) {
        ReactGA.initialize("UA-129736657-1");
        setIsGaInitialised(true);
        return;
      }

      // Track the locaton in GA
      ReactGA.pageview(window.location.pathname);
    }
  }, [canTrackUser, isGaInitialised]);

  return (
    <>
      <CookieBanner
        message="We use cookies to understand your site usage. That way we can tailor the site navigation, content and marketing to your specific needs."
        wholeDomain={true}
      />
      <Router>
        <Suspense fallback={<FallbackComponent />}>
          <Nav />
          <div className="page-wrapper">
            <>
              {displayBoots && <BootPrints />}
              <Switch>
                <Route exact path="/" component={Homepage} />
                <Route path="/our-story*" component={OurStoryPage} />
                <Route path="/line-up*" component={LineUpPage} />
                <Route path="/artist/:id" component={ArtistPage} />
                <Route path="/tickets" component={TicketsPage} />
                <Route path="/programme*" component={ProgrammePage} />
                <Route path="/supporters*" component={SupportersPage} />
                <Route path="/contact-us*" component={ContactUsPage} />
                <Route path="/venues*" component={VenuesPage} />
                <Route path="/accommodation*" component={AccommodationPage} />
                <Route path="/gallery*" component={GalleryPage} />
                <Route path="/news*" component={NewsPage} />
                <Route path="/page/:id" component={Page} />
                <Route
                  path="/cms"
                  component={() => {
                    window.location.href = "https://app.graphcms.com/";
                    return null;
                  }}
                />
                <Route component={NotFoundPage} />
              </Switch>
            </>
          </div>
          <Footer />
        </Suspense>
      </Router>
    </>
  );
};

const FallbackComponent = () => (
  <>
    <Nav />
    <div className="page-wrapper" />
    <Footer />
  </>
);

export default App;
